export default [
  {
    key: 'no',
    label: 'field.orderNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'outletId',
    label: 'field.outlet',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'outlet',
    selectionKey: 'id',
    selectionLabel: 'label',
    localization: true,
  },
  {
    key: 'shipmentDate',
    label: 'field.expectDeliveryDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'orderDate',
    label: 'field.orderedDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'user',
    selectionKey: 'id',
    selectionLabel: 'name',
  },
  {
    key: 'status',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: false,
    options: [
      { text: 'Open', value: 'Open' },
      { text: 'Released', value: 'Released' },
      { text: 'Posted', value: 'Posted' },
      { text: 'Cancelled', value: 'Cancelled' },
    ],
  },
];
