export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    stickyColumn: true,
    variant: 'light',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'no',
    label: 'field.orderNo',
    hideToggle: true,
  },
  {
    key: 'externalDocumentNo',
    label: 'field.orderReference',
    hideToggle: true,
  },
  {
    key: 'shipmentDate',
    label: 'field.expectDeliveryDate',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'sellToCustomerName',
    subKey: 'sellToCustomerNo',
    label: 'field.outlet',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'sellToAddress',
    label: 'field.address',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'amount',
    label: 'field.total',
    type: 'currency',
    hideToggle: true,
  },
  {
    key: 'amountIncludingVat',
    label: 'field.totalWithVat',
    type: 'currency',
    hideToggle: true,
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'orderDate',
    label: 'field.orderedDate',
    hideToggle: true,
    type: 'date',
  },
  {
    key: 'isCreatedInErp',
    label: 'field.createdAt',
  },
  {
    key: 'remark',
    label: 'field.remark',
  },
  {
    key: 'createdAt',
    label: 'field.createdDate',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
  },
];
