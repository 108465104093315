<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <sync-button
                  :resource="resource"
                  :repository="SalesOrderRepository"
                  :module="$t('field.salesOrder')"
                  @success="onSyncSuccess"
                />
              </b-col>
              <b-col class="pl-0" cols="auto">
                <b-button
                  :disabled="!$can('create', resource)"
                  :variant="!$can('create', resource) ? 'secondary' : 'primary'"
                  :to="{ name: `create-${route}` }"
                >
                  {{ $t("button.makeNewOrder") }}
                </b-button>
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          @sort-changed="sortChanged"
          :key="`table-${key}`"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
          :viewable="true"
          :updatable="false"
        >
          <template #cell(status)="data">
            <span :class="data.item.status">
              {{ data.item.status }}
            </span>
          </template>
          <template #cell(isCreatedInErp)="data">
            <b-badge variant="info" pill v-if="data.item.isCreatedInErp">
              ERP
            </b-badge>
            <b-badge variant="primary" pill v-else> DP </b-badge>
          </template>
          <template #back-button="data">
            <download-pdf-button
              v-b-tooltip.hover
              :title="$t('button.downloadDeliveryNote')"
              :repository="
                !data.item.salesShipmentId || data.item.status != 'Posted'
                  ? SalesOrderRepository
                  : SalesShipmentRepository
              "
              :id="
                !data.item.salesShipmentId || data.item.status != 'Posted'
                  ? data.item.id
                  : data.item.salesShipmentId
              "
              :file-name="$t('field.deliveryNote') + ' - ' + (data.item.salesShipmentNo ? data.item.salesShipmentNo : data.item.no)"
              :disabled="!data.item.id"
              :variant="!data.item.id ? 'secondary' : 'success'"
              pill
              size="sm"
            >
              <i class="las la-truck"></i>
            </download-pdf-button>
            <download-pdf-button
              v-b-tooltip.hover
              :title="$t('button.downloadInvoice')"
              :repository="SalesOrderRepository"
              :id="data.item.salesInvoiceId"
              :file-name="
                $t('breadcrumb.invoice') + ' - ' + data.item.salesInvoiceNo
              "
              :disabled="
                !data.item.salesInvoiceId || data.item.status != 'Posted'
              "
              :variant="
                !data.item.salesInvoiceId || data.item.status != 'Posted'
                  ? 'secondary'
                  : 'success'
              "
              pill
              size="sm"
            >
              <i class="las la-file-invoice-dollar"></i>
            </download-pdf-button>
          </template>
        </n-table>
        <n-pagination
          :key="`page-${query.page}`"
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BFormGroup, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NColumnVisibility from "@/components/NColumnVisibility";
import SyncButton from "@/components/sync/SyncButton.vue";
import DownloadPdfButton from "@/components/DownloadPdfButton";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const SalesOrderRepository = Repository.get("salesOrder");
const SalesShipmentRepository = Repository.get("salesShipment");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BBadge,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NAsyncSingleSelect,
    NColumnVisibility,
    SyncButton,
    DownloadPdfButton,

    vSelect,
  },
  watch: {
    perPage() {
      this.changePage();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.salesOrder;
      },
      set(value) {
        this.$store.commit("column/SET_SALES_ORDER_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        outletId: this.$route.query.outletId || [],
        createdAt: this.$route.query.createdAt || [],
        createdBy: this.$route.query.createdBy || [],
        shipmentDate: this.$route.query.shipmentDate || [],
        orderDate: this.$route.query.orderDate || [],
        status: this.$route.query.status || ["Open", "Released"],
        no: this.$route.query.no || null,
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        outletId: this.$route.query.outletId || [],
        createdAt: this.$route.query.createdAt || [],
        createdBy: this.$route.query.createdBy || [],
        shipmentDate: this.$route.query.shipmentDate || [],
        orderDate: this.$route.query.orderDate || [],
        status: this.$route.query.status || ["Open", "Released"],
        no: this.$route.query.no || null,
      },
      SalesOrderRepository,
      SalesShipmentRepository,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.search();
    },
    onSyncSuccess() {
      this.query.page = 1;
      this.getData(true);
    },
    getData(isSync = false) {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      SalesOrderRepository.index({
        ...this.query,
        searchFields: this.searchFields,
        query: [
          {
            search: "Order",
            searchby: "documentType",
            searchoperator: "=",
          },
        ],
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
            if (isSync) {
              this.$hideSyncing();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                autoHideDelay: 2000,
                props: {
                  title: this.$t("alert.syncSuccess"),
                  iconType: "line-awesome",
                  icon: "las la-cloud-download-alt",
                  variant: "light-success",
                  textVariant: "success",
                },
              });
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    const resource = "agent-sales-order";
    const route = "sales-order";

    return {
      fields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
